import { Link, graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from '../article.module.css';

const Aside = () => {
  const { nanoCms } = useStaticQuery(graphql`
    query {
      nanoCms {
        articles(isPublished: true, limit: 5) {
          edges {
            node {
              title
              description
              category
              ogpImage
              url
              content
              updatedAt
              timestamp
            }
          }
        }
      }
    }
  `);
  var articles = nanoCms.articles;
  articles = articles.edges.map((edge) => edge.node);

  return (
    <aside className={styles.sidenav}>
      <div className={styles.ad}></div>
      <div className={styles.popularArticle}>
        <h2 className={styles.asideH2}>新着記事</h2>
        {articles.map((article, index) => (
          <article className={styles.articleItemAside}>
            <div className={styles.articleLeft}>
              <Link to={`/articles/${article.url}/`}>
                {article.ogpImage ? (
                  <img src={article.ogpImage} alt={article.title} />
                ) : (
                  <StaticImage
                    src="../../../images/nanocms.png"
                    alt={article.title}
                  />
                )}
              </Link>
            </div>
            <div className={styles.articleRight}>
              <Link to={`/articles/${article.url}/`}>
                <p>{article.title}</p>
              </Link>
            </div>
          </article>
        ))}
      </div>
    </aside>
  );
};

export default Aside;
