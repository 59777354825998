// extracted by mini-css-extract-plugin
export var ad = "article-module--ad--27e0e";
export var articleBar = "article-module--articleBar--c733c";
export var articleItem = "article-module--articleItem--e0254";
export var articleItemAside = "article-module--articleItemAside--0ee96";
export var articleLeft = "article-module--articleLeft--94f38";
export var articleMain = "article-module--articleMain--bd80a";
export var articleRight = "article-module--articleRight--24d1b";
export var articleRightDate = "article-module--articleRightDate--f8349";
export var articleRightSubtitle = "article-module--articleRightSubtitle--fba42";
export var asideContentBody = "article-module--asideContentBody--3f909";
export var asideContentRight = "article-module--asideContentRight--545c0";
export var asideData = "article-module--asideData--b9d41";
export var asideH2 = "article-module--asideH2--5574c";
export var blockArticle = "article-module--blockArticle--d12f2";
export var breadcrumb = "article-module--breadcrumb--dba1c";
export var breadcrumbCategory = "article-module--breadcrumb-category--7759c";
export var card = "article-module--card--b1575";
export var cardTitle = "article-module--cardTitle--0fc05";
export var cardWrapper = "article-module--cardWrapper--5cf88";
export var categoryBar = "article-module--categoryBar--84605";
export var cntWrapper = "article-module--cntWrapper--95287";
export var cntWrapperIndex = "article-module--cntWrapperIndex--6aaa4";
export var contactWrapper = "article-module--contactWrapper--c216b";
export var featuredImage = "article-module--featuredImage--9d548";
export var footerReserved = "article-module--footerReserved--b9954";
export var mainnav = "article-module--mainnav--9274d";
export var newArticle = "article-module--newArticle--e9a10";
export var paginationBox = "article-module--paginationBox--0fd0a";
export var paginationBtn = "article-module--paginationBtn--f3736";
export var paginationBtnActive = "article-module--paginationBtnActive--2edfb";
export var paginationBtnSide = "article-module--paginationBtnSide--80c1e";
export var popularArticle = "article-module--popularArticle--7f49a";
export var post = "article-module--post--c08b2";
export var postDate = "article-module--postDate--0d538";
export var sidenav = "article-module--sidenav--709fc";
export var subTitle = "article-module--subTitle--8b645";
export var wrapperCard = "article-module--wrapperCard--fc16d";